<template>
	<div class="listItem" @click="
      $router.push(
        `/${$store.state.platform == 'pc' ? 'GoodsDetail' : 'Detail_Mobile'}?id=${
          detail.spuId
        }`
      )
    ">
		<div class="pic">
			<img class="regular" :src="detail.cover" />
			<template v-if="!isInLike">
				<img class="like" :src="
            detail.isCollect == 0
              ? require('../../assets/icon/like.png')
              : require('../../assets/icon/liked.png')
          " @click.stop="$bus.$emit('addLike', detail.spuId)" />
				<!-- <img
          class="basket"
          src="../../assets/icon/basket.png"
          @click.stop="$bus.$emit('addCart', detail.skus[0].skuId)"
        /> -->
			</template>
			<template v-else>
				<img class="like" src="../../assets/icon/close.png"
					@click.stop="$bus.$emit('removeCollect', detail.collectId)" />
				<!-- <img
          class="basket"
          src="../../assets/icon/basket.png"
          @click.stop="$bus.$emit('addCart', detail.skus[0].skuId)"
        /> -->
			</template>
		</div>

		<div class="name">{{ detail.spuName }}</div>
		<div class="buttons">
			<div style="display: flex;align-items: center;">
				<div class="price" :style="{'color':detail.originPrice== 0?'#000000':'red'}">€{{ detail.price }}</div>
				<div class="originPrice" v-if="detail.originPrice != 0">€{{ detail.originPrice }}</div>
			</div>
			<div class="btn deep" @click.stop="handleAddCart">
				{{ $t("goodDetail.addCart") }}
			</div>
		</div>
		<div @click.stop="() => {}">
			<el-dialog title="Talla" v-if="listVisible" :visible.sync="listVisible" width="30%" append-to-body
				:lock-scroll="false" :before-close="handleClose">
				<div class="list-content" v-if="detail.specs && detail.specs.length != 0">
					<p v-for="(val, index) in parse_specs" :key="index" class="list-sku" @click.stop="()=>{
							let activeItem = detail.skus[detail.skus.findIndex((node)=>{ return node.specMode == val.map((item)=>{return item.mode;}).join('');})];
					$bus.$emit('addCart', activeItem.skuId || activeItem.sku_id,detail);
					listVisible = false;				
					}">
						{{ val.map((item)=>{return item.value;}).join(" ") }}
					</p>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	export default {
		name: "ListItem",
		props: {
			detail: {
				type: Object,
				default: () => {
					return {};
				},
			},
			isInLike: {
				type: Boolean,
				default: false,
			},
		},
		watch: {
			listVisible(val) {
				//console.log(this.detail);
			},
		},
		data() {
			return {
				show_listItem_hover: false,
				listVisible: false,
				parse_specs: []
			};
		},
		created() {
			this.parse_specs = this.calcSpaces(this.detail.specs);
		},
		methods: {
			//规格排列组合
			calcSpaces(transArr) {
				let resultArr = [];

				function get(array, index, val) {
					if (!array[index]) {
						resultArr.push(val);
						return;
					};

					array[index].specs.forEach((v, i) => {
						get(array, index + 1, index === 0 ? [v] : [...val, v])
					})
				}
				get(transArr, 0);
				return resultArr;
			},
			changeOver(isshow) {
				if (this.$store.state.platform == 'pc') {
					if (this.listVisible) {
						this.show_listItem_hover = false;
					} else {
						if (this._timer) {
							clearTimeout(this._timer);
						}
						this._timer = setTimeout(() => {

							this.show_listItem_hover = isshow;
						}, 100);
					}
				}
			},
			handleClose(done) {
				done();
			},
			handleAddCart() {
				console.log(this.detail);
				if (this.detail.skus.length == 1) {
					this.$bus.$emit(
						"addCart",
						this.detail.skus[0].skuId || this.detail.skus[0].sku_id,
						this.detail
					);
				} else {
					this.listVisible = true;
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.listItem:hover {
		transform: scale(1.1); //原本的图片的大小，图片原来的大小不变
		transition: all 0.1s;
		box-shadow: 0px 0px 10px 2px #cdcdcd;
		border-radius: 10px;
		background-color: #fff;
		overflow: hidden;
		position: relative;
		z-index: 1;
	}

	.el-dialog__wrapper.dialog-fade-enter-active,
	.el-dialog__wrapper.dialog-fade-leave-active {
		animation: none !important;
	}

	.listItem {
		width: 100%;
		padding-bottom: 10px;

		.pic {
			position: relative;
			height: 29.1vw;
			background: #f2f2f4;
			cursor: pointer;

			&>img.like {
				width: 24px;
				height: 22px;
				position: absolute;
				top: 30px;
				right: 30px;
				cursor: pointer;
			}

			&>img.basket {
				width: 24px;
				height: 22px;
				position: absolute;
				top: 92px;
				right: 30px;
				cursor: pointer;
			}
		}

		.name {
			font-size: 18px;
			font-weight: 600;
			line-height: 25px;
			height: 50px;
			margin: 40px 0 8px;
			text-align: center;
		}

		.buttons {
			display: flex;
			align-items: center;
			justify-content: center;

			.price {
				font-size: 18px;
				font-weight: bold;
				line-height: 25px;
				height: 25px;
			}

			.originPrice {
				text-decoration: line-through;
				margin-left: 10px;
			}

			.btn {
				height: 30px;
				padding: 5px 20px;
				background: #ffffff;
				border-radius: 6px;
				border: 1px solid #123178;
				text-align: center;
				line-height: 30px;
				font-size: 12px;
				font-weight: 600;
				color: #123178;
				cursor: pointer;
				margin-left: 40px;

				&.deep {
					background-color: #e8c9a0;
					border: 1px solid #e8c9a0;
					color: #fff;

					&:hover {
						background-color: #eecc77;
					}

				}

				&:hover {
					background-color: #021450;
				}
			}
		}



	}

	/deep/.el-dialog__header {
		border-bottom: 1px solid #ccc;
	}

	/deep/.el-dialog__body {
		padding: 10px 20px 20px;
	}

	/deep/ .list-content {
		width: 100%;

		.list-sku {
			width: 100%;
			border-bottom: 1px solid #eee;
			line-height: 35px;
			font-size: 14px;
			height: 35px;
			cursor: pointer;
		}

		.list-sku:hover {
			font-size: 16px;
			font-weight: bold;
			color: #133077;
		}
	}

	@media screen and (max-width: 500px) {
		/deep/.el-dialog {
			width: 60% !important;
		}

		.listItem:hover {
			transform: scale(1); //原本的图片的大小，图片原来的大小不变
			transition: all 0.1s;
			box-shadow: none;
			border-radius: 0px;
		}

		.listItem {
			.pic {
				height: 55vw !important;

				&>img.like {
					width: 4vw;
					height: 3.6vw;
					position: absolute;
					top: 3vw;
					right: 3vw;
					cursor: pointer;
				}

				&>img.basket {
					width: 4vw;
					height: 4vw;
					position: absolute;
					top: 10vw;
					right: 3vw;
					cursor: pointer;
				}
			}

			.name {
				font-size: 4vw;
				font-weight: 600;
				margin: 3vw 0 1vw;
			}

			.buttons {
				display: flex;
				flex-direction: column;
				align-items: center;

				.price {
					font-size: 18px;
					font-weight: bold;
					line-height: 25px;
					height: 25px;
					margin: 20px 0 0;
				}

				.originPrice {
					margin-top: 20px;
				}

				.btn {
					height: 30px;
					padding: 5px 20px;
					background: #ffffff;
					border-radius: 6px;
					border: 1px solid #123178;
					text-align: center;
					line-height: 30px;
					font-size: 12px;
					font-weight: 600;
					color: #123178;
					cursor: pointer;
					margin-left: 0px;

					&.deep {
						background-color: #e8c9a0;
						border: 1px solid #e8c9a0;
						color: #fff;

						&:hover {
							background-color: #eecc77;
						}

					}


					&:hover {
						background-color: #021450;
					}
				}
			}
		}
	}
</style>
