var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"listItem",on:{"click":function($event){return _vm.$router.push(
        ("/" + (_vm.$store.state.platform == 'pc' ? 'GoodsDetail' : 'Detail_Mobile') + "?id=" + (_vm.detail.spuId))
      )}}},[_c('div',{staticClass:"pic"},[_c('img',{staticClass:"regular",attrs:{"src":_vm.detail.cover}}),(!_vm.isInLike)?[_c('img',{staticClass:"like",attrs:{"src":_vm.detail.isCollect == 0
              ? require('../../assets/icon/like.png')
              : require('../../assets/icon/liked.png')},on:{"click":function($event){$event.stopPropagation();return _vm.$bus.$emit('addLike', _vm.detail.spuId)}}})]:[_c('img',{staticClass:"like",attrs:{"src":require("../../assets/icon/close.png")},on:{"click":function($event){$event.stopPropagation();return _vm.$bus.$emit('removeCollect', _vm.detail.collectId)}}})]],2),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.detail.spuName))]),_c('div',{staticClass:"buttons"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"price",style:({'color':_vm.detail.originPrice== 0?'#000000':'red'})},[_vm._v("€"+_vm._s(_vm.detail.price))]),(_vm.detail.originPrice != 0)?_c('div',{staticClass:"originPrice"},[_vm._v("€"+_vm._s(_vm.detail.originPrice))]):_vm._e()]),_c('div',{staticClass:"btn deep",on:{"click":function($event){$event.stopPropagation();return _vm.handleAddCart($event)}}},[_vm._v(" "+_vm._s(_vm.$t("goodDetail.addCart"))+" ")])]),_c('div',{on:{"click":function($event){$event.stopPropagation();return (function () {})($event)}}},[(_vm.listVisible)?_c('el-dialog',{attrs:{"title":"Talla","visible":_vm.listVisible,"width":"30%","append-to-body":"","lock-scroll":false,"before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.listVisible=$event}}},[(_vm.detail.specs && _vm.detail.specs.length != 0)?_c('div',{staticClass:"list-content"},_vm._l((_vm.parse_specs),function(val,index){return _c('p',{key:index,staticClass:"list-sku",on:{"click":function($event){$event.stopPropagation();return (function (){
							var activeItem = _vm.detail.skus[_vm.detail.skus.findIndex(function (node){ return node.specMode == val.map(function (item){return item.mode;}).join('');})];
					_vm.$bus.$emit('addCart', activeItem.skuId || activeItem.sku_id,_vm.detail);
					_vm.listVisible = false;				
					})($event)}}},[_vm._v(" "+_vm._s(val.map(function (item){return item.value;}).join(" "))+" ")])}),0):_vm._e()]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }